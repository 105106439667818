<template>
    
    <div v-if="view === 'mobile'" class="h-32 w-full p-2 float-left">
        <div v-if="data.type === 'EY Stories'"
            class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md">
            <div class="h-6 bg-green-15 flex flex-row justify-between items-center px-4
                flex-none">
                <span class="text-green font-semibold text-xs">EY Stories</span>
                <span class="text-xxs text-black">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col px-2">
                <div class="flex-1 w-full flex flex-col justify-center items-center py-2">
                    <span class="font-semibold text-xs mb-2 text-center" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span v-show="isValid(data.link)" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                        @click="goLink(data.link)" style="color: #78849E">
                        <i class="mdi mdi-open-in-new text-font-gray"></i>+info
                    </span>
                </div>
                <div class="h-auto w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer text-xxs" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xxs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
        <div v-else-if="data.type === 'Presidence'" class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md">
            <div class="h-6 bg-font-gray-15 flex flex-row justify-between items-center px-4
                flex-none">
                <span class="text-font-gray font-semibold text-xs">Presidencia</span>
                <span class="text-xxs text-black">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col">
                <div class="flex-1 w-full flex flex-col justify-center items-center py-2">
                    <span class="font-semibold text-xs mb-2 text-center" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                        @click="openFile(data.file, 'file')" style="color: #78849E">
                        <i class="mdi text-font-gray mr-auto" :class="extension(data.file)"></i>
                        +info
                    </span>
                </div>
                <div class="h-auto w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer text-xxs" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xxs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
        <div v-else class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md">
            <div class="h-12 bg-font-gray-15 flex flex-row justify-between items-center px-4">
                <span class="text-font-gray font-semibold">{{ data.type}}</span>
                <span class="text-xs text-black">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col">
                <div class="flex-1 w-full flex flex-col justify-center items-center py-2">
                    <span class="font-semibold text-lg mb-2 text-center truncate-3" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                          @click="openFile(data.file, 'file')" style="color: #78849E">+info
                    </span>
                </div>
                <div class="h-auto w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer text-xxs" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xxs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
    </div>
    <div v-else class="h-48 w-96 p-2 float-left">
        <div v-if="data.type === 'EY Stories'" class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md">
            <div class="h-12 bg-green-15 flex flex-row justify-between items-center px-4" :class="'bg-' + color(data.type)+ '-30'">
                <span class="text-green font-semibold">EY Stories</span>
                <span class="text-xs text-black">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col px-2">
                <div class="flex-1 w-full mt-4 flex flex-col justify-center items-center py-2">
                    <span class="font-semibold text-lg mb-2 text-center truncate-3" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span class="flex flex-direction-row space-x-3">
                        <span v-if="isValid(data.link)" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                              @click="goLink(data.link)" style="color: #78849E">
                            <i class="mdi mdi-open-in-new text-font-gray"></i>
                        </span>
                        <span v-if="data.file && data.file.length > 0" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                              @click="openFile(data.file, 'file')" style="color: #78849E">
                            <i class="mdi text-font-gray mr-auto" :class="extension(data)"></i>+info
                        </span>
                    </span>
                </div>
                <div class="flex-1 w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
        <div v-else-if="data.type === 'Presidence'" class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md" >
            <div class="h-12 bg-font-gray-15 flex flex-row justify-between items-center px-4" :class="'bg-' + color(data.type) + '-100'">
                <span class="text-font-gray font-semibold">Presidencia</span>
                <span class="text-xs text-black">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col">
                <div class="flex-1 w-full mt-4 flex flex-col justify-center items-center py-2">
                    <span class="font-semibold text-lg mb-2 text-center truncate-3" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span class="flex flex-direction-row space-x-3">
                        <span v-if="isValid(data.link)" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                              @click="goLink(data.link)" style="color: #78849E">
                            <i class="mdi mdi-open-in-new text-font-gray"></i>
                        </span>
                        <span v-if="data.file && data.file.length > 0" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                              @click="openFile(data.file, 'file')" style="color: #78849E">
                            <i class="mdi text-font-gray mr-auto" :class="extension(data)"></i>+info
                        </span>
                    </span>
                </div>
                <div class="flex-1 w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
        <div v-else class="h-full w-full flex flex-col bg-box rounded-lg overflow-hidden shadow-md">
            <div class="h-12 bg-orange-400 flex flex-row justify-between items-center px-4" :class="'bg-' + color(data.type)">
                <span class="text-font-gray font-semibold">{{ data.type }}</span>
                <span class="text-xs" :class="{'text-black': data.type === 'EY Podcasts',
                        'text-black': data.type === 'EY Stories', 'text-black': data.type === 'Presidence'}">
                    {{ data.date | moment('DD/MM/YYYY') }}
                </span>
            </div>
            <div class="flex-1 flex flex-col">
                <div class="flex-1 w-full flex mt-4 flex-col justify-center items-center py-2">
                    <span class="font-semibold text-lg mb-2 text-center truncate-3" style="color: #B4BCCE">
                        {{ data.title }}
                    </span>
                    <span class="flex flex-direction-row space-x-3">
                        <span v-if="isValid(data.link)" class="font-semibold text-font-gray text-md cursor-pointer"
                            @click="goLink(data.link)" style="color: #78849E">
                            <i class="mdi mdi-open-in-new text-font-gray"></i>
                        </span>
                        <span v-if="data.file && data.file.length > 0" class="font-semibold text-font-gray text-md cursor-pointer mt-1"
                            @click="openFile(data.file, 'file')" style="color: #78849E">
                            <i class="mdi text-font-gray mr-auto" :class="extension(data)"></i>+info
                        </span>
                    </span>
                </div>
                <div class="flex-1 w-full mt-auto flex flex-row justify-end items-center py-2 px-4">
                    <i class="mdi mdi-thumb-up-outline text-font-gray mr-2 cursor-pointer" :class="melike(data.likes)" @click="dolike(data.id)"></i>
                    <span class="text-xs">{{ data.likes.length }}</span>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
import ColorByOptionsCommsAndPod from "@/utils/ColorByOptionsCommsAndPod";

export default {
    props: ['data', 'view'],
    computed: {
        type() { return 'stories' }
    },
    methods: {
        color(type) { return ColorByOptionsCommsAndPod(type) },
        goLink(link) { this.$goLink(link) },
        openFile(file, type) { this.$openFile(file, type) },
        extension(elem) {
            let result = ''
            result += this.$iconByExtension(elem.file)
            return result
        },
        isValid(link) { return this.$is_url(link) },
        dolike(id) {
            this.axios.post(process.env.VUE_APP_BACKEY_CMS_URL + 'like?resource=CommAndPodcast&id='+id)
                .then(response => {
                    this.load();
                })
        },
        melike(comms) { return this.$meLike(comms) }
    }
}
</script>